import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-irving-texas.png'
import image0 from "../../images/cities/scale-model-of-mandalay-canal-walk-at-las-colinas-in-irving-texas.png"
import image1 from "../../images/cities/scale-model-of-the-grapevine-escape-in-irving-texas.png"
import image2 from "../../images/cities/scale-model-of-christmas-in-the-branch-in-irving-texas.png"
import image3 from "../../images/cities/scale-model-of-dallas-panic-room-in-irving-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Irving'
            state='Texas'
            image={image}
            lat='32.8577'
            lon='-96.97'
            attractions={ [{"name": "Mandalay Canal Walk at Las Colinas", "vicinity": "215 Mandalay Canal, Irving", "types": ["point_of_interest", "establishment"], "lat": 32.865969, "lng": -96.93919119999998}, {"name": "The Grapevine Escape", "vicinity": "160 N Main St, Grapevine", "types": ["point_of_interest", "establishment"], "lat": 32.9421754, "lng": -97.07733659999997}, {"name": "Christmas in the Branch", "vicinity": "12700 Denton Dr, Farmers Branch", "types": ["point_of_interest", "establishment"], "lat": 32.9202122, "lng": -96.89625740000002}, {"name": "Dallas Panic Room", "vicinity": "2001 W Irving Blvd, Irving", "types": ["point_of_interest", "establishment"], "lat": 32.8272375, "lng": -96.97139399999998}] }
            attractionImages={ {"Mandalay Canal Walk at Las Colinas":image0,"The Grapevine Escape":image1,"Christmas in the Branch":image2,"Dallas Panic Room":image3,} }
       />);
  }
}